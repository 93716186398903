<template>
    <div class="goods-item">

        <button class="col-btn" @click="collect()" :class="{ 'collected': goods.is_collect }">
            <template v-if="goods.is_collect">
                <img width="12" src="/assets/images/collected.png" alt="">
            </template>
            <template v-else>
                <img style="opacity: 40%" width="12" src="/assets/images/collect-none.png" alt="">
            </template>
        </button>

        <div class="image-container" :style="`background-image: url(${goods.white_image || goods.thumb})`"></div>
        <div class="goods-detail">
            <div class="goods-name">
                <template v-if="!hiddenPlate ">
                    <div class="goods-plate">
                        <span v-if="goods.from === 'jd' " class="iconfont pc-jingdong"></span>
                        <span v-else-if="goods.from === 'tb' " class="iconfont pc-taobao"></span>
                        <span v-else-if="goods.from === 'vip' " class="iconfont pc-weipinhui"></span>
                        <span v-else-if="goods.from === 'wechat' " class="pc-wechat"></span>
                        <span v-else-if="goods.from === 'yc-shop' " class="pc-yc"></span>
                    </div>
                </template>

                <div class="name">{{ goods.sku_name || goods.title || goods.product_name }}</div>
            </div>
            <div class="goods-prices">
                <div class="price">
                    <span class="lowest">
                        <span class="fh">￥</span>
                        <template v-if="goods.lowest_price">{{ goods.lowest_price }}</template>
                        <template v-else-if="goods.min_price">
                            {{ (goods.min_price / 100).toFixed(2) }}
                        </template>
                        <template v-else-if="goods.price">
                            {{ goods.price }}
                        </template>
                    </span>
                </div>
                <div class="comm" v-if="!hiddenComm && (goods.from !== 'wechat') && goods.commission &&  goods.commission > 0 ">
                    <span class="dz">搭赚</span> <span class="fh">￥</span>
                    <span class="comm-am">{{ goods.commission }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import api from "../repo/api";

export default {
    name: "GoodsItem",
    props: {
        goods: Object,
        hiddenPlate: Boolean,
        hiddenComm: {
            type: Boolean,
            default() {
                return false
            }
        }
    },
    methods: {
        collect() {

            if (this.goods.collecting) return

            this.goods.collecting = true


            api.goodsCollect(this.goods.id, (data) => {

                this.goods.collecting = false

                if (data.code === 0) {
                    this.goods.is_collect = !this.goods.is_collect
                }

            })

        },
    }
}
</script>

<style lang="less" scoped>
.goods-item {
    height: 265px;
    cursor: pointer;
    display: block;
    text-decoration: none;
    position: relative;
}

.goods-name {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;

    .name {
        flex: 1;
        overflow: hidden;
        white-space: nowrap;
        word-break: keep-all;
    }
}

.goods-plate {
    width: 16px;
    height: 16px;
    line-height: 16px;
    background-color: #dddddd;
    margin-right: 4px;
}

.image-container {
    width: 200px;
    height: 200px;

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;


    background-color: #F5F5F5;
    margin-bottom: 8px;
}

.goods-detail {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 24px;
}

.goods-prices {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.fh {
    font-size: 12px;
}

.lowest {
    color: rgba(227, 29, 26, 1);
    display: inline-block;
    margin-right: 9px;

    .fh {
        color: rgba(227, 29, 26, 1);
    }
}

.origin {
    color: rgba(0, 0, 0, 0.4);
    text-decoration: line-through;
}

.comm {
    .fh {
        color: rgba(255, 173, 0, 1);
    }
}

.comm-am {
    color: rgba(255, 173, 0, 1);
}

.dz {
    color: #999;
}

.col-btn {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: white;
    border-radius: 100%;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    cursor: pointer;

    span {
        font-size: 14px;
    }

    &.collected {
        opacity: 1;
    }
}

.goods-item:hover .col-btn {
    opacity: 1;
}

</style>