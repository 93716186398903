<template>
    <yh-page current="/collections">
        <div class="header">
            <ul class="locations">
                <li>搭配选购</li>
                <li>搭配详情</li>
            </ul>
        </div>
        <div v-if="loading" class="loading-container">
            <img src="/assets/images/loading.gif"/>
        </div>
        <div v-else-if="outfit === null" class="loading-container">
            <div class="text-center">
                没有找到对应的搭配
                <div>
                    <button class="btn" @click="gotoList">返回搭配列表</button>
                </div>
            </div>
        </div>
        <div v-else class="detail-body">

            <div class="left" id="detail-left">

                <div class="outfit-img">
                    <img :src="outfit.thumb" alt="">
                </div>

                <template v-if="outfit.status === 2 && outfit.reviews ">

                    <div class="reject-text">
                        <span class="iconfont pc-jinggao"></span>
                        <div>
                            {{ outfit.reviews[0].reason }}
                        </div>
                    </div>

                </template>

            </div>
            <div class="right">

                <div class="title">{{ outfit.title }}</div>

                <div class="prices-commission">
                    <div class="prices">

                        <div class="s-price">
                            <span class="unit">￥</span>
                            <span>{{ outfit.price }}</span>
                        </div>
                    </div>
                    <div class="collect">
                        <span class="iconfont pc-weishoucang"></span> {{ outfit.collect_num }}
                    </div>
                </div>

                <div class="summary">{{ outfit.summary }}</div>

                <div class="user">
                    <div class="item-title">搭配师信息</div>
                    <l-a :href="`/m/${ outfit.member.account }`" class="user-info">
                        <img :src="outfit.member.avatar" alt="">
                        <div>
                            <div class="user-name">{{ outfit.member.name }}</div>
                            <div class="user-summary">{{ outfit.member.summary }}</div>
                        </div>
                    </l-a>
                </div>
                <div class="tags">
                    <div class="item-title">搭配信息</div>
                    <template v-for="(tag,k) in tags">
                        <div :key="k" v-if="outfit[tag.key]" class="tag-item">
                            - {{ tag.name }}：
                            <template v-for="(item,ik) in outfit[tag.key] ">

                                <span :key="ik">
                                    {{ item.name }}
                                    <template v-if="ik < (outfit[tag.key].length - 1) ">、</template>
                                </span>

                            </template>
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <div class="outfit-content" v-if="outfit">

            <div class="content-header">相关单品</div>

            <div>

                <template v-for="(goods,k) in outfit.goods">

                    <div :key="k" class="goods-group">

                        <div :key="k" class="goods-block" :class="{'third' : (k + 1)%3 === 0}">

                            <span class="no">单品{{ nos[k] }}</span>
                            <goods-item hidden-comm :goods="goods"/>

                        </div>

                        <div class="goods-list">
                            <div class="list-title">相关推荐</div>
                            <div class="no-goods">- 暂无相关推荐 -</div>
                        </div>

                    </div>
                </template>

            </div>
        </div>

    </yh-page>

</template>

<script>
    import api from "@/repo/api";
    import GoodsItem from "@/components/GoodsItem";
    import YhPage from "../../../layout/YhPage";
    import LA from "../../../components/LA";

    export default {
        name: "Detail",
        components: {LA, YhPage, GoodsItem},
        data() {
            return {
                loading: true,
                outfit: null,
                tags: [
                    {
                        name: '适宜着装场合',
                        key: 'occasion'
                    },
                    {
                        name: '适宜风格人群',
                        key: 'styles'
                    },
                    {
                        name: '搭配风格氛围',
                        key: 'atmosphere',
                    },
                    {
                        name: '话题',
                        key: 'topics'
                    },
                    {
                        name: '季节',
                        key: 'season',
                    }
                ],
                nos: ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十']
            }
        },
        mounted() {
            this.getOutfit()
        },
        methods: {
            editOutfit() {
                this.$router.push(`/deck?outfit_id=${this.outfit.id}`)
            },
            detailUpdate(data) {

                this.showDetailModal = false

                this.$swal.fire({
                    title: '提交中，请稍候...',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    showConfirmButton: false,
                })

                data['id'] = this.outfit.id

                api.createOutfit(data, (result) => {

                    this.$swal.close();

                    if (result.code !== 0) {
                        this.$swal({
                            title: result.msg,
                            icon: 'error',
                            confirmButtonText: '好'
                        });

                        return
                    }

                    this.$swal.fire({
                        title: '修改成功',
                        icon: 'success',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        showConfirmButton: false,
                    })

                    setTimeout(() => {
                        location.reload()
                    }, 3000)

                })
            },
            getOutfit() {
                let id = this.$route.params.id

                api.getOutfitDetail(id, (data) => {
                    this.loading = false
                    if (data.code !== 0) return

                    this.outfit = data.data

                })
            },
            gotoList() {
                this.$router.push('/collections')
            }
        }
    }
</script>

<style lang="less" scoped>
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        padding-bottom: 15px;
        margin-bottom: 40px;

        border-bottom: 1px solid #EEEEEE;

        li {
            float: left;

            font-size: 14px;
            font-weight: 400;
            color: #999999;
            line-height: 20px;
            padding-right: 15px;
            margin-right: 8px;
            position: relative;
            list-style: none;

            &:after {
                content: '/';
                position: absolute;
                right: 0;
                bottom: 0;
            }

            &:last-child {
                color: #333333;

                &:after {
                    display: none;
                }
            }
        }
    }

    .edit-btn {
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.45);
        line-height: 20px;

        .iconfont {
            font-size: 12px;
        }
    }

    .loading-container {

        font-size: 14px;
        line-height: 24px;
        color: #333333;
        padding-top: 200px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 48px;
            height: 48px;
        }

        .btn {
            margin-top: 16px;
        }
    }

    .detail-body {
        display: flex;
        justify-content: left;

        .left {
            width: 600px;
        }

        .right {
            flex: 1;
            padding-left: 48px;
        }
    }

    .outfit-img {
        width: 600px;
        height: 600px;

        position: relative;
        border: 1px solid #F5F5F5;
        background-color: #F5F5F5;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 590px;
            height: 590px;
        }
    }

    .title {
        font-size: 24px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        line-height: 28px;
        margin-bottom: 15px;
    }

    .goods-block {
        position: relative;
        cursor: pointer;
        width: 304px;
        margin-right: 110px;

        /deep/ .goods-item {

            height: 368px;

            .image-container {
                width: 304px;
                height: 304px;
            }
        }

        .no {
            position: absolute;
            left: -16px;
            top: 16px;
            height: 33px;
            font-size: 14px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 20px;
            padding: 2px 7px;
            background-image: url("/assets/images/goods-no.png");
            background-repeat: no-repeat;
        }
    }

    .goods-group {
        display: flex;
        margin-bottom: 170px;
    }

    .goods-list {
        flex: 1;
        .list-title, .no-goods {
            font-size: 16px;
            font-weight: 400;
            color: #999999;
            line-height: 22px;
            margin-bottom: 32px;
        }

        .no-goods {
            font-size: 12px;
            text-align: center;
            width: 100%;
        }
    }

    .summary {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.45);
        line-height: 24px;
        margin-bottom: 40px;
    }

    .tag-item {
        overflow: hidden;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
        margin-bottom: 15px;

        span {
            color: inherit;
        }
    }

    .item-btn {
        background: #F5F5F5;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
        border: none;
        cursor: initial;
        height: 32px;
        margin-right: 15px;
        margin-bottom: 15px;
        float: left;
    }

    .prices-commission {
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .prices {
        display: flex;
        align-items: center;
    }

    .s-price {
        display: flex;
        font-size: 24px;
        align-items: baseline;
        margin-right: 32px;

        span {
            color: rgba(227, 29, 26, 0.8);
        }

        .unit {
            font-size: 14px;
            color: rgba(227, 29, 26, 0.8);
        }
    }

    .commission {
        font-size: 14px;
        background: #FFEECB;
        line-height: 24px;
        font-weight: 400;
        color: #FFAD00;
        padding: 0 8px;
        display: inline-block;
        height: 24px;

        span {
            font-size: 14px;
            color: #FFAD00;

            span {
                font-size: 12px;
                color: #FFAD00;
            }
        }
    }

    .collect {
        .iconfont {
            font-size: 12px;
        }

        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
    }

    .tags {
        margin-bottom: 32px;
    }

    .item-title {
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
        margin-bottom: 24px;
    }

    .user-info {
        margin-bottom: 45px;
        text-decoration: none;
        display: flex;

        img {
            width: 54px;
            height: 54px;
            border-radius: 100%;
            border: 1px solid #dddddd;
            margin-right: 15px;
        }

        .user-name {
            font-size: 14px;
            font-weight: 500;
            color: #333333;
            line-height: 20px;
            margin-bottom: 8px;
        }

        .user-summary {
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            line-height: 20px;
        }
    }

    .content-header {
        text-align: center;
        margin-top: 80px;
        margin-bottom: 125px;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
        position: relative;
        overflow: hidden;

        &:before, &:after {
            content: ' ';
            width: 50%;
            height: 1px;
            background-color: #EEEEEE;
            top: 11px;
            position: absolute;
        }

        &:before {
            left: -60px;
        }

        &:after {
            right: -60px;
        }
    }


</style>